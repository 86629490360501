<template>
  <div>
    <div class="ui form">
      <div
        v-if="isOnline"
        class="inline fields"
      >
        <label
          data-tooltip="Choisir un type de sélection de signalements pour effectuer une action"
          data-position="bottom left"
        >Mode de sélection :</label>
        <div class="field">
          <div class="ui radio checkbox">
            <input
              id="edit-status"
              v-model="mode"
              type="radio"
              name="mode"
              value="edit-status"
            >
            <label for="edit-status">Édition de statut</label>
          </div>
        </div>
        <div class="field">
          <div class="ui radio checkbox">
            <input
              id="edit-attributes"
              v-model="mode"
              type="radio"
              name="mode"
              value="edit-attributes"
            >
            <label for="edit-attributes">Édition d'attribut</label>
          </div>
        </div>
        <div class="field">
          <div class="ui radio checkbox">
            <input
              id="delete-features"
              v-model="mode"
              type="radio"
              name="mode"
              value="delete-features"
            >
            <label for="delete-features">Suppression de signalement</label>
          </div>
        </div>
      </div>
    </div>

    <div
      data-tab="list"
      class="dataTables_wrapper no-footer"
    >
      <table
        id="table-features"
        class="ui compact table unstackable dataTable"
        aria-describedby="Liste des signalements du projet"
      >
        <thead>
          <tr>
            <th
              v-if="isOnline"
              scope="col"
              class="dt-center"
            >
              Sélection
            </th>

            <th
              scope="col"
              class="dt-center"
            >
              <div
                :class="isOnline ? 'pointer' : 'disabled'"
                @click="changeSort('status')"
              >
                Statut
                <i
                  :class="{
                    down: isSortedAsc('status'),
                    up: isSortedDesc('status'),
                  }"
                  class="icon sort"
                  aria-hidden="true"
                />
              </div>
            </th>
            <th
              scope="col"
              class="dt-center"
            >
              <div
                :class="isOnline ? 'pointer' : 'disabled'"
                @click="changeSort('feature_type')"
              >
                Type
                <i
                  :class="{
                    down: isSortedAsc('feature_type'),
                    up: isSortedDesc('feature_type'),
                  }"
                  class="icon sort"
                  aria-hidden="true"
                />
              </div>
            </th>
            <th
              scope="col"
              class="dt-center"
            >
              <div
                :class="isOnline ? 'pointer' : 'disabled'"
                @click="changeSort('title')"
              >
                Nom
                <i
                  :class="{
                    down: isSortedAsc('title'),
                    up: isSortedDesc('title'),
                  }"
                  class="icon sort"
                  aria-hidden="true"
                />
              </div>
            </th>
            <th
              scope="col"
              class="dt-center"
            >
              <div
                :class="isOnline ? 'pointer' : 'disabled'"
                @click="changeSort('created_on')"
              >
                Date de création
                <i
                  :class="{
                    down: isSortedAsc('created_on'),
                    up: isSortedDesc('created_on'),
                  }"
                  class="icon sort"
                  aria-hidden="true"
                />
              </div>
            </th>
            <th
              scope="col"
              class="dt-center"
            >
              <div
                :class="isOnline ? 'pointer' : 'disabled'"
                @click="changeSort('updated_on')"
              >
                Dernière modification
                <i
                  :class="{
                    down: isSortedAsc('updated_on'),
                    up: isSortedDesc('updated_on'),
                  }"
                  class="icon sort"
                  aria-hidden="true"
                />
              </div>
            </th>
            <th
              v-if="user"
              scope="col"
              class="dt-center"
            >
              <div
                :class="isOnline ? 'pointer' : 'disabled'"
                @click="changeSort('display_creator')"
              >
                Auteur
                <i
                  :class="{
                    down: isSortedAsc('display_creator'),
                    up: isSortedDesc('display_creator'),
                  }"
                  class="icon sort"
                  aria-hidden="true"
                />
              </div>
            </th>
            <th
              v-if="user"
              scope="col"
              class="dt-center"
            >
              <div
                :class="isOnline ? 'pointer' : 'disabled'"
                @click="changeSort('display_last_editor')"
              >
                Dernier éditeur
                <i
                  :class="{
                    down: isSortedAsc('display_last_editor'),
                    up: isSortedDesc('display_last_editor'),
                  }"
                  class="icon sort"
                  aria-hidden="true"
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(feature, index) in paginatedFeatures"
            :key="index"
          >
            <td
              v-if="isOnline"
              id="select"
              class="dt-center"
            >
              <div
                :class="['ui checkbox', {disabled: !checkRights(feature)}]"
              >
                <input
                  :id="feature.feature_id"
                  v-model="checked"
                  type="checkbox"
                  :value="feature.feature_id"
                  :disabled="!checkRights(feature)"
                  name="select"
                  @input="storeClickedFeature(feature)"
                >
                <label for="select" />
              </div>
            </td>

            <td
              id="status"
              class="dt-center"
            >
              <div
                v-if="feature.status === 'archived'"
                data-tooltip="Archivé"
              >
                <i
                  class="grey archive icon"
                  aria-hidden="true"
                />
              </div>
              <div
                v-else-if="feature.status === 'pending'"
                data-tooltip="En attente de publication"
              >
                <i
                  class="teal hourglass outline icon"
                  aria-hidden="true"
                />
              </div>
              <div
                v-else-if="feature.status === 'published'"
                data-tooltip="Publié"
              >
                <i
                  class="olive check icon"
                  aria-hidden="true"
                />
              </div>
              <div
                v-else-if="feature.status === 'draft'"
                data-tooltip="Brouillon"
              >
                <i
                  class="orange pencil alternate icon"
                  aria-hidden="true"
                />
              </div>
            </td>
            <td
              id="type"
              class="dt-center"
            >
              <router-link
                :to="{
                  name: 'details-type-signalement',
                  params: {
                    feature_type_slug: feature.feature_type.slug,
                  },
                }"
                class="ellipsis space-left"
              >
                {{ feature.feature_type.title }}
              </router-link>
            </td>
            <td
              id="name"
              class="dt-center"
            >
              <router-link
                :to="{
                  name: 'details-signalement-filtre',
                  query: { ...queryparams, offset: queryparams.offset + index }
                }"
                class="ellipsis space-left"
              >
                {{ feature.title || feature.feature_id }}
              </router-link>
            </td>
            <td
              id="create"
              class="dt-center"
            >
              {{ feature.created_on | formatDate }}
            </td>
            <td
              id="update"
              class="dt-center"
            >
              {{ feature.updated_on | formatDate }}
            </td>
            <td
              v-if="user"
              id="author"
              class="dt-center"
            >
              {{ feature.display_creator || ' ---- ' }}
            </td>
            <td
              v-if="user"
              id="last_editor"
              class="dt-center"
            >
              {{ feature.display_last_editor || ' ---- ' }}
            </td>
          </tr>
          <tr
            v-if="featuresCount === 0"
            class="odd"
          >
            <td
              colspan="5"
              class="dataTables_empty"
              valign="top"
            >
              Aucune donnée disponible
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="pageNumbers.length > 1"
        id="table-features_info"
        class="dataTables_info"
        role="status"
        aria-live="polite"
      >
        Affichage de l'élément {{ pagination.start + 1 }} à
        {{ displayedPageEnd }}
        sur {{ featuresCount }} éléments
      </div>
      <div
        v-if="pageNumbers.length > 1 && isOnline"
        id="table-features_paginate"
        class="dataTables_paginate paging_simple_numbers"
      >
        <a
          id="table-features_previous"
          :class="[
            'paginate_button previous',
            { disabled: pagination.currentPage === 1 },
          ]"
          aria-controls="table-features"
          data-dt-idx="0"
          tabindex="0"
          @click="$emit('update:page', 'previous')"
        >Précédent</a>
        <span>
          <span v-if="pagination.currentPage >= 5">
            <a
              key="page1"
              class="paginate_button"
              aria-controls="table-features"
              data-dt-idx="1"
              tabindex="0"
              @click="$emit('update:page', 1)"
            >{{ 1 }}</a>
            <span class="ellipsis">…</span>
          </span>
          <a
            v-for="pageNumber in displayedPageNumbers"
            :key="'page' + pageNumber"
            :class="[
              'paginate_button',
              { current: pageNumber === pagination.currentPage },
            ]"
            aria-controls="table-features"
            data-dt-idx="1"
            tabindex="0"
            @click="$emit('update:page', pageNumber)"
          >{{ pageNumber }}</a>
          <span v-if="(lastPageNumber - pagination.currentPage) >= 4">
            <span class="ellipsis">…</span>
            <a
              :key="'page' + lastPageNumber"
              class="paginate_button"
              aria-controls="table-features"
              data-dt-idx="1"
              tabindex="0"
              @click="$emit('update:page', lastPageNumber)"
            >{{ lastPageNumber }}</a>
          </span>
        </span>
        <a
          id="table-features_next"
          :class="[
            'paginate_button next',
            { disabled: pagination.currentPage === pageNumbers.length },
          ]"
          aria-controls="table-features"
          data-dt-idx="7"
          tabindex="0"
          @click="$emit('update:page', 'next')"
        >Suivant</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { formatStringDate } from '@/utils';

export default {
  name: 'FeatureListTable',

  filters: {
    formatDate(value) {
      return formatStringDate(value);
    },
  },

  beforeRouteLeave (to, from, next) {
    if (to.name !== 'editer-attribut-signalement') {
      this.UPDATE_CHECKED_FEATURES([]); // empty if not needed anymore
    }
    next(); // continue navigation
  },
  
  props: {
    paginatedFeatures: {
      type: Array,
      default: null,
    },
    pageNumbers: {
      type: Array,
      default: null,
    },
    checkedFeatures: {
      type: Array,
      default: null,
    },
    featuresCount: {
      type: Number,
      default: 0,
    },
    pagination: {
      type: Object,
      default: null,
    },
    sort: {
      type: Object,
      default: null,
    },
    queryparams: {
      type: Object,
      default: null,
    },
    editAttributesFeatureType: {
      type: String,
      default: null,
    },
  },

  computed: {
    ...mapGetters(['permissions']),
    ...mapState([
      'user',
      'USER_LEVEL_PROJECTS',
      'isOnline'
    ]),
    ...mapState('projects', ['project']),
    ...mapState('feature', ['clickedFeatures', 'massMode']),

    mode: {
      get() {
        return this.massMode;
      },
      set(newMode) {
        this.TOGGLE_MASS_MODE(newMode);
        this.UPDATE_CLICKED_FEATURES([]);
        this.UPDATE_CHECKED_FEATURES([]);
      },
    },

    userStatus() {
      return this.USER_LEVEL_PROJECTS ? this.USER_LEVEL_PROJECTS[this.$route.params.slug] : '';
    },

    checked: {
      get() {
        return this.checkedFeatures;
      },
      set(newChecked) {
        this.$store.commit('feature/UPDATE_CHECKED_FEATURES', newChecked);
      },
    },

    displayedPageEnd() {
      return this.featuresCount <= this.pagination.end
        ? this.featuresCount
        : this.pagination.end;
    },

    lastPageNumber() {
      return this.pageNumbers.slice(-1)[0];
    },

    displayedPageNumbers() {
      //* s'il y a moins de 5 pages, renvoyer toutes les pages
      if (this.lastPageNumber < 5) {
        return this.pageNumbers;
      }
      //* si la page courante est inférieur à 5, la liste commence à l'index 0 et on retourne 5 pages
      let firstPageInList = 0;
      let pagesQuantity = 5;
      //* à partir de la 5ième page et jusqu'à la 4ième page avant la fin : n'afficher que 3 page entre les ellipses et la page courante doit être au milieu
      if (this.pagination.currentPage >= 5 && !((this.lastPageNumber - this.pagination.currentPage) < 4)) {
        firstPageInList = this.pagination.currentPage - 2;
        pagesQuantity = 3;
      }
      //* à partir de 4 résultat avant la fin afficher seulement les 5 derniers résultats
      if ((this.lastPageNumber - this.pagination.currentPage) < 4) {
        firstPageInList = this.lastPageNumber - 5;
      }
      return this.pageNumbers.slice(firstPageInList, firstPageInList + pagesQuantity);
    },
  },

  methods: {
    ...mapMutations('feature', [
      'UPDATE_CLICKED_FEATURES',
      'UPDATE_CHECKED_FEATURES',
      'TOGGLE_MASS_MODE',
    ]),

    storeClickedFeature(feature) {
      if (this.massMode === 'edit-attributes') { // if modifying attributes
        if (this.checkedFeatures.length === 0) { // store feature type slug to restrict selection for next selected features
          this.$emit('update:editAttributesFeatureType', feature.feature_type.slug);
        } else if (this.checkedFeatures.length === 1 && this.checkedFeatures[0] === feature.feature_id) {
          this.$emit('update:editAttributesFeatureType', null); // delete feature type slug if last checkedFeatures is unselected, to allow other types selection
        }
      }
      this.UPDATE_CLICKED_FEATURES([
        ...this.clickedFeatures,
        { feature_id: feature.feature_id, feature_type: feature.feature_type.slug }
      ]);
    },

    canDeleteFeature(feature) {
      if (this.userStatus === 'Administrateur projet' || this.user.is_superuser) {
        return true; //* can delete all
      }
      //* others can delete only their own features
      return feature.display_creator === this.user.username;
    },

    canEditFeature(feature) {
      const permissions = {
        'Administrateur projet' : ['draft', 'pending', 'published', 'archived'],
        Modérateur : ['draft', 'pending', 'published'],
        'Super Contributeur' : ['draft', 'pending', 'published'],
        Contributeur : ['draft', 'pending', 'published'],
      };

      if (this.checkedFeatures.length > 0 && // check if selection should be restricted to a specific feature type, for attributes modification
        feature.feature_type.slug !== this.editAttributesFeatureType &&
        this.massMode === 'edit-attributes') {
        return false;
      } else if (this.user.is_superuser) {
        return true;
      } else if (this.userStatus === 'Contributeur' && feature.display_creator !== `${this.user.first_name} ${this.user.last_name}`) {
        return false;
      } else if (permissions[this.userStatus]) {
        return permissions[this.userStatus].includes(feature.status);
      } else {
        return false;
      }
    },

    checkRights(feature) {
      if (this.massMode.includes('edit')) {
        return this.canEditFeature(feature);
      } else if (this.massMode === 'delete-features') {
        return this.canDeleteFeature(feature);
      }
    },

    isSortedAsc(column) {
      return this.sort.column === column && this.sort.ascending;
    },
    isSortedDesc(column) {
      return this.sort.column === column && !this.sort.ascending;
    },

    changeSort(column) {
      if (!this.isOnline) return;
      if (this.sort.column === column) {
        //changer only order
        this.$emit('update:sort', {
          column: this.sort.column,
          ascending: !this.sort.ascending,
        });
      } else { // change column and reset order
        this.$emit('update:sort', { column, ascending: true });
      }
    },
  },
};
</script>

<style scoped>
/* datatables */
.dataTables_wrapper {
  position: relative;
  clear: both;
}
.dataTables_paginate {
  margin-bottom: 1rem;
}
table.dataTable th.dt-center, table.dataTable td.dt-center, table.dataTable td.dataTables_empty {
  text-align: center;
}
.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: #333;
}
.dataTables_wrapper .dataTables_info {
  clear: both;
  float: left;
  padding-top: 0.755em;
}
.dataTables_wrapper .dataTables_paginate {
  float: right;
  text-align: right;
  padding-top: 0.25em;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: #333 !important;
  border: 1px solid #979797;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #fff),
    color-stop(100%, #dcdcdc)
  );
  background: -webkit-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -moz-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -ms-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: -o-linear-gradient(top, #fff 0%, #dcdcdc 100%);
  background: linear-gradient(to bottom, #fff 0%, #dcdcdc 100%);
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  color: #333 !important;
  border: 1px solid transparent;
  border-radius: 2px;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: white !important;
  border: 1px solid #111;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #585858),
    color-stop(100%, #111)
  );
  background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
  background: -moz-linear-gradient(top, #585858 0%, #111 100%);
  background: -ms-linear-gradient(top, #585858 0%, #111 100%);
  background: -o-linear-gradient(top, #585858 0%, #111 100%);
  background: linear-gradient(to bottom, #585858 0%, #111 100%);
}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #666 !important;
  border: 1px solid transparent;
  background: transparent;
  box-shadow: none;
}

.dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 1em;
}

i.icon.sort:not(.down):not(.up) {
  color: rgb(220, 220, 220);
}

.grey {
  color: #bbbbbb;
}

.ui.dropdown .menu .left.menu, .ui.dropdown > .left.menu .menu {
  margin-right: 0 !important;
}

.table-mobile-buttons {
  margin-bottom: 1em;
}

/* increase contrast between available checkboxes and disabled ones */
#table-features .ui.disabled.checkbox label::before {
  background-color: #fbf5f5;;  
}

@media only screen and (min-width: 761px) {
  .table-mobile-buttons {
    display: none !important;
  }
}
/*
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media only screen and (max-width: 760px) {
  .table-mobile-buttons {
    display: flex !important;
    }
  /* hide table border */
  .ui.table {
    border: none !important;
    margin-top: 2em;
  }
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr { /* style as a card */
    border: 1px solid #ccc;
    border-radius: 7px;
    margin-bottom: 3vh;
    padding: 0 2vw .5em 2vw;
    box-shadow: rgba(50, 50, 50, 0.1) 2px 5px 10px ;
  }

  td {
    /* Behave like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }
  .ui.table tr td {
    border-top: none;
  }
  .ui.compact.table td {
    padding: .2em;
  }
  td:nth-of-type(1) {
    border: none !important;
    padding: .25em !important;
  }
  td:nth-of-type(8) {
    border-bottom: none !important;
  }
  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    left: 6px;
    padding-right: 10px;
    white-space: nowrap;
  }
  /*
	Label the data
	*/
  td#select:before {
    content: "";
  }
  td#status:before {
    content: "Statut";
  }
  td#type:before {
    content: "Type";
  }
  td#name:before {
    content: "Nom";
  }
  td#create:before {
    content: "Date de création";
  }
  td#update:before {
    content: "Dernière modification";
  }
  td#author:before {
    content: "Auteur";
  }
  td#last_editor:before {
    content: "Dernier éditeur";
  }

  table.dataTable th.dt-center, table.dataTable td.dt-center, table.dataTable td.dataTables_empty {
    text-align: right;
  }

  #select .ui.checkbox {
    position: absolute;
    left: calc(-1vw - 20px);
    top: -12px;
    min-height: 24px;
    font-size: 1rem;
    line-height: 24px;
    min-width: 24px;
  }
  #select .ui.checkbox .box::before, #select .ui.checkbox label::before,
  #select .ui.checkbox .box::after, #select .ui.checkbox label::after {
    width: 24px;
    height: 24px;
  }
  /* cover all the card to ease selection by user */
  #select .ui.checkbox {
    width: 100%;
  }
  #select .ui.checkbox input[type="checkbox"] {
    width: calc(100% + 1vw + 20px + 4vw);
    height: calc(14em + 12px);
  }
  /* keep the links above the checkbox input to receive the click event */
  table a {
    z-index: 4;
    position: sticky;
  }
  #select .ui.checkbox .box::before, #select .ui.checkbox label::before {
    border-radius: 12px;
  }
  #select .ui.checkbox .box::after, #select .ui.checkbox label::after {
    font-size: 18px;
  }
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate {
    width: 100%;
    text-align: center;
    margin: .5em 0;
  }
  /* preserve space to not overlap column label */
  .space-left {
    margin-left: 2.5em;
  }
}
@media only screen and (max-width: 410px) {
  .ui.table tr td {
    border: none;
  }
}
</style>
