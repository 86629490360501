<template>
  <div id="project-features">
    <div class="column">
      <FeaturesListAndMapFilters
        :show-map="showMap"
        :features-count="featuresCount"
        :pagination="pagination"
        :edit-attributes-feature-type="editAttributesFeatureType"
        @set-filter="setFilters"
        @reset-pagination="resetPagination"
        @fetch-features="fetchPagedFeatures"
        @show-map="setShowMap"
        @edit-status="modifyStatus"
        @toggle-delete-modal="toggleDeleteModal"
      />

      <div
        :class="['ui tab active map-container', { 'visible': showMap }]"
        data-tab="map"
      >
        <div
          id="map"
          ref="map"
        />
        <SidebarLayers
          v-if="basemaps && map"
          ref="sidebar"
        />
        <Geocoder />
        <div 
          id="popup" 
          class="ol-popup"
        >
          <a 
            id="popup-closer" 
            href="#" 
            class="ol-popup-closer"
          />
          <div 
            id="popup-content"
          />
        </div>
      </div>
      <FeatureListTable
        v-show="!showMap"
        :paginated-features="paginatedFeatures"
        :page-numbers="pageNumbers"
        :checked-features.sync="checkedFeatures"
        :features-count="featuresCount"
        :pagination="pagination"
        :sort="sort"
        :edit-attributes-feature-type.sync="editAttributesFeatureType"
        :queryparams="queryparams"
        @update:page="handlePageChange"
        @update:sort="handleSortChange"
      />

    
      <!-- MODAL ALL DELETE FEATURE TYPE -->
      <div
        v-if="isDeleteModalOpen"
        class="ui dimmer modals page transition visible active"
        style="display: flex !important"
      >
        <div
          :class="[
            'ui mini modal',
            { 'active visible': isDeleteModalOpen },
          ]"
        >
          <i
            class="close icon"
            aria-hidden="true"
            @click="isDeleteModalOpen = false"
          />
          <div class="ui icon header">
            <i
              class="trash alternate icon"
              aria-hidden="true"
            />
            Êtes-vous sûr de vouloir effacer
            <span v-if="checkedFeatures.length === 1"> un signalement ? </span>
            <span v-else> ces {{ checkedFeatures.length }} signalements ? </span>
          </div>
          <div class="actions">
            <button
              type="button"
              class="ui red compact fluid button"
              @click="deleteAllFeatureSelection"
            >
              Confirmer la suppression
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions, mapMutations } from 'vuex';
import mapService from '@/services/map-service';
import Geocoder from '@/components/Map/Geocoder';
import featureAPI from '@/services/feature-api';

import FeaturesListAndMapFilters from '@/components/Project/FeaturesListAndMap/FeaturesListAndMapFilters';
import SidebarLayers from '@/components/Map/SidebarLayers';
import FeatureListTable from '@/components/Project/FeaturesListAndMap/FeatureListTable';

const initialPagination = {
  currentPage: 1,
  pagesize: 15,
  start: 0,
  end: 15,
};

export default {
  name: 'FeaturesListAndMap',

  components: {
    FeaturesListAndMapFilters,
    SidebarLayers,
    Geocoder,
    FeatureListTable,
  },

  data() {
    return {
      editAttributesFeatureType: null,
      currentLayer: null,
      featuresCount: 0,
      form: {
        type: {
          selected: '',
        },
        status: {
          selected: '',
        },
        title: null,
      },
      isDeleteModalOpen: false,
      lat: null,
      lng: null,
      map: null,
      paginatedFeatures: [],
      pagination: { ...initialPagination },
      projectSlug: this.$route.params.slug,
      queryparams: {},
      showMap: true,
      sort: {
        column: 'updated_on',
        ascending: true,
      },
      zoom: null,
    };
  },

  computed: {
    ...mapState([
      'isOnline'
    ]),
    ...mapState('projects', [
      'project',
    ]),
    ...mapState('feature', [
      'checkedFeatures',
      'clickedFeatures',
    ]),
    ...mapState('feature-type', [
      'feature_types',
    ]),
    ...mapState('map', [
      'basemaps',
    ]),

    API_BASE_URL() {
      return this.$store.state.configuration.VUE_APP_DJANGO_API_BASE;
    },

    pageNumbers() {
      return this.createPagesArray(this.featuresCount, this.pagination.pagesize);
    },
  },


  watch: {
    isOnline(newValue, oldValue) {
      if (newValue != oldValue && !newValue) {
        this.DISPLAY_MESSAGE({
          comment: 'Les signalements du projet non mis en cache ne sont pas accessibles en mode déconnecté',
        });
      }
    },
  },

  mounted() {
    this.UPDATE_CHECKED_FEATURES([]); // empty for when turning back from edit attributes page
    if (!this.project) {
      // Chargements des features et infos projet en cas d'arrivée directe sur la page ou de refresh
      Promise.all([
        this.$store.dispatch('projects/GET_PROJECT', this.projectSlug),
        this.$store.dispatch('projects/GET_PROJECT_INFO', this.projectSlug)
      ]).then(()=> this.initPage());
    } else {
      this.initPage();
    }
  },

  destroyed() {
    //* allow user to change page if ever stuck on loader
    this.$store.commit('DISCARD_LOADER');
  },

  methods: {
    ...mapMutations([
      'DISPLAY_MESSAGE',
    ]),
    ...mapActions('feature', [
      'DELETE_FEATURE',
    ]),

    ...mapMutations('feature', [
      'UPDATE_CHECKED_FEATURES'
    ]),

    setShowMap(newValue) {
      this.showMap = newValue;
      //* expanded sidebar is visible under the list, even when the map is closed (position:absolute), solved by closing it whin switching to list
      if (newValue === false && this.$refs.sidebar) this.$refs.sidebar.toggleSidebar(false);
    },
    resetPagination() {
      this.pagination = { ...initialPagination };
    },
    setFilters(e) {
      const filter = Object.keys(e)[0];
      const value = Object.values(e)[0];
      if (filter === 'title') {
        this.form[filter] = value;
      } else {
        this.form[filter].selected = value;
      }
    },

    toggleDeleteModal() {
      this.isDeleteModalOpen = !this.isDeleteModalOpen;
    },

    async modifyStatus(newStatus) {
      if (this.checkedFeatures.length > 0) {
        const feature_id = this.checkedFeatures[0];
        const feature = this.clickedFeatures.find((el) => el.feature_id === feature_id);
        if (feature) {
          featureAPI.updateFeature({
            feature_id,
            feature_type__slug: feature.feature_type,
            project__slug: this.projectSlug,
            newStatus
          }).then((response) => {
            if (response && response.data && response.status === 200) {
              const newCheckedFeatures = [...this.checkedFeatures];
              newCheckedFeatures.splice(this.checkedFeatures.indexOf(response.data.id), 1);
              this.UPDATE_CHECKED_FEATURES(newCheckedFeatures);
              this.modifyStatus(newStatus);
            } else {
              this.DISPLAY_MESSAGE({
                comment: `Le signalement ${feature.title} n'a pas pu être modifié`,
                level: 'negative'
              });
              this.fetchPagedFeatures();
            }
          });
        }
      } else {
        this.fetchPagedFeatures();
        this.DISPLAY_MESSAGE({
          comment: 'Tous les signalements ont été modifié avec succès.',
          level: 'positive'
        });
      }
    },

    deleteAllFeatureSelection() {
      const initialFeaturesCount = this.featuresCount;
      const initialCurrentPage = this.pagination.currentPage;
      const promises = this.checkedFeatures.map(
        (feature_id) => this.DELETE_FEATURE({ feature_id, noFeatureType: true })
      );
      Promise.all(promises).then((response) => {
        const deletedFeaturesCount = response.reduce((acc, curr) => curr.status === 204 ? acc += 1 : acc, 0);
        const newFeaturesCount = initialFeaturesCount - deletedFeaturesCount;
        const newPagesArray = this.createPagesArray(newFeaturesCount, this.pagination.pagesize);
        const newLastPageNum = newPagesArray[newPagesArray.length - 1];
        this.$store.commit('feature/UPDATE_CHECKED_FEATURES', []);
        if (initialCurrentPage > newLastPageNum) { //* if page doesn't exist anymore
          this.toPage(newLastPageNum); //* go to new last page
        } else {
          this.fetchPagedFeatures();
        }
      })
        .catch((err) => console.error(err));
      this.toggleDeleteModal();
    },

    modifyFeaturesAttributes() {
      console.log('modifyFeaturesAttributes');
    },

    onFilterChange() {
      if (mapService.getMap() && mapService.mvtLayer) {
        mapService.mvtLayer.changed();
      }
    },

    initPage() {
      this.sort = {
        column: this.project.feature_browsing_default_sort.replace('-', ''),
        ascending: this.project.feature_browsing_default_sort.includes('-')
      };
      this.initMap();
    },

    initMap() {
      this.zoom = this.$route.query.zoom || '';
      this.lat = this.$route.query.lat || '';
      this.lng = this.$route.query.lng || '';

      var mapDefaultViewCenter =
        this.$store.state.configuration.DEFAULT_MAP_VIEW.center;
      var mapDefaultViewZoom =
        this.$store.state.configuration.DEFAULT_MAP_VIEW.zoom;

      this.map = mapService.createMap(this.$refs.map, {
        zoom: this.zoom,
        lat: this.lat,
        lng: this.lng,
        mapDefaultViewCenter,
        mapDefaultViewZoom,
        maxZoom: this.project.map_max_zoom_level,
        interactions : { doubleClickZoom :false, mouseWheelZoom:true, dragPan:true }
      });

      document.addEventListener('change-layers-order', (event) => {
        // Reverse is done because the first layer in order has to be added in the map in last.
        // Slice is done because reverse() changes the original array, so we make a copy first
        mapService.updateOrder(event.detail.layers.slice().reverse());
      });

      // --------- End sidebar events ----------
      this.$nextTick(() => {
        const project_id = this.projectSlug.split('-')[0];
        const mvtUrl = `${this.API_BASE_URL}features.mvt`;
        mapService.addVectorTileLayer({
          url: mvtUrl,
          projectId: project_id,
          featureTypes: this.feature_types,
          formFilters: this.form,
          queryParams: this.queryparams,
        });
      });

      this.fetchPagedFeatures();
    },

    fetchBboxNfit(queryParams) {
      featureAPI
        .getFeaturesBbox(this.projectSlug, queryParams)
        .then((bbox) => {
          if (bbox) {
            mapService.fitBounds(bbox);
          }
        });
    },

    //* Paginated Features for table *//
    getFeatureTypeSlug(title) {
      const featureType = this.feature_types.find((el) => el.title === title);
      return featureType ? featureType.slug : null;
    },

    getAvalaibleField(orderField) {
      let result = orderField;
      if (orderField === 'display_creator') {
        result = 'creator';
      } else if (orderField === 'display_last_editor') {
        result = 'last_editor';
      }
      return result;
    },

    buildQueryString() {
      let queryString = '';
      const typeFilter = this.getFeatureTypeSlug(this.form.type.selected);
      const statusFilter = this.form.status.selected.value;

      this.queryparams['offset'] = this.pagination.start;
      if (typeFilter) {
        this.queryparams['feature_type_slug'] = typeFilter;
        queryString += `&feature_type_slug=${typeFilter}`;
      }
      if (statusFilter) {
        this.queryparams['status__value'] = statusFilter;
        queryString += `&status__value=${statusFilter}`;
      }
      if (this.form.title) {
        this.queryparams['title'] = this.form.title;
        queryString += `&title=${this.form.title}`;
      }
      if (this.sort.column) {
        let ordering = `${this.sort.ascending ? '-' : ''}${this.getAvalaibleField(this.sort.column)}`;
        this.queryparams['ordering'] = ordering;
        queryString += `&ordering=${ordering}`;
      }
      return queryString;
    },

    fetchPagedFeatures(newUrl) {
      if (!this.isOnline) {
        this.DISPLAY_MESSAGE({
          comment: 'Les signalements du projet non mis en cache ne sont pas accessibles en mode déconnecté',
        });
        return;
      }
      let url = `${this.API_BASE_URL}projects/${this.projectSlug}/feature-paginated/?limit=${this.pagination.pagesize}&offset=${this.pagination.start}`;
      //* if receiving next & previous url (// todo : might be not used anymore, to check)
      if (newUrl && typeof newUrl === 'string') {
        //newUrl = newUrl.replace("8000", "8010"); //* for dev uncomment when using proxy link
        url = newUrl;
      }
      const queryString = this.buildQueryString();

      url += queryString;
      this.$store.commit(
        'DISPLAY_LOADER',
        'Récupération des signalements en cours...'
      );
      featureAPI.getPaginatedFeatures(url)
        .then((data) => {
          if (data) {
            this.featuresCount = data.count;
            this.previous = data.previous;
            this.next = data.next;
            this.paginatedFeatures = data.results;
          }
          //* bbox needs to be updated with the same filters
          if (this.paginatedFeatures.length) {
            this.fetchBboxNfit(queryString);
          }
          this.onFilterChange(); //* use paginated event to watch change in filters and modify features on map
          this.$store.commit('DISCARD_LOADER');
        });
    },

    //* Pagination for table *//

    createPagesArray(featuresCount, pagesize) {
      const totalPages = Math.ceil(
        featuresCount / pagesize
      );
      return [...Array(totalPages).keys()].map((pageNumb) => {
        ++pageNumb;
        return pageNumb;
      });
    },

    handlePageChange(page) {
      if (page === 'next') {
        this.toNextPage();
      } else if (page === 'previous') {
        this.toPreviousPage();
      } else if (typeof page === 'number') {
        //* update limit and offset
        this.toPage(page);
      }
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.fetchPagedFeatures({
        filterType: undefined,
        filterValue: undefined,
      });
    },

    toPage(pageNumber) {
      const toAddOrRemove =
        (pageNumber - this.pagination.currentPage) * this.pagination.pagesize;
      this.pagination.start += toAddOrRemove;
      this.pagination.end += toAddOrRemove;
      this.pagination.currentPage = pageNumber;
      this.fetchPagedFeatures();
    },

    toPreviousPage() {
      if (this.pagination.currentPage !== 1) {
        if (this.pagination.start > 0) {
          this.pagination.start -= this.pagination.pagesize;
          this.pagination.end -= this.pagination.pagesize;
          this.pagination.currentPage -= 1;
        }
        this.fetchPagedFeatures();
      }
    },

    toNextPage() {
      if (this.pagination.currentPage !== this.pageNumbers.length) {
        if (this.pagination.end < this.featuresCount) {
          this.pagination.start += this.pagination.pagesize;
          this.pagination.end += this.pagination.pagesize;
          this.pagination.currentPage += 1;
        }
        this.fetchPagedFeatures();
      }
    },
  },
};
</script>


<style lang="less" scoped>
.map-container {
  width: 80vw;
  transform: translateX(-50%);
  margin-left: 50%;
  visibility: hidden;
  position: absolute;
  #map {
    min-height: 0;
  }
}
.map-container.visible {
  visibility: visible;
  position: relative;
  width: 100%;

  .sidebar-container {
    left: -250px;
  }

  .sidebar-container.expanded {
    left: 0;
  }

  #map {
    width: 100%;
    min-height: 310px;
    height: calc(100vh - 310px);
    border: 1px solid grey;
    /* To not hide the filters */
    z-index: 1;
  }
}

@media screen and (max-width: 767px) {
  #project-features {
    margin: 1em auto 1em;
  }
  .map-container {
    width: 100%;
    position: relative;
  }
}
</style>

